<template>
  <div class="confirmBoxModule"></div>
</template>

<script>
import ConfirmConfig from "@/utils/confirmConfig";
export default {
  name: "ConfirmBoxModule",
  methods: {
    show(data) {
      const { title, content, type } = data;
      return new Promise((resolve, reject) => {
        this.$confirm({
          title: title,
          content: () => {
            if (type === ConfirmConfig.CONFIRM_TYPE_ERROR) {
              return <div style="color:red;">{content}</div>;
            } else if (type === ConfirmConfig.CONFIRM_TYPE_SUCCESS) {
              return <div>{content}</div>;
            } else if (type === ConfirmConfig.CONFIRM_TYPE_WARNING) {
              return <div>{content}</div>;
            }
          },
          icon: () => {
            if (type === ConfirmConfig.CONFIRM_TYPE_ERROR) {
              return <a-icon type="close-circle" style="color: red" />;
            } else if (type === ConfirmConfig.CONFIRM_TYPE_SUCCESS) {
              return <a-icon type="check-circle" style="color: #52c41a" />;
            } else if (type === ConfirmConfig.CONFIRM_TYPE_WARNING) {
              return <a-icon type="exclamation-circle" style="color: #faad14" />;
            }
          },
          onOk() {
            resolve(true);
          },
          onCancel() {
            reject(false);
          },
          class: "test",
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
