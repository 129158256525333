/**确认框类型
 * CONFIRM_TYPE_SUCCESS: success
 * CONFIRM_TYPE_ERROR: error
 * CONFIRM_TYPE_WARNING: warning
 */
export default {
    CONFIRM_TYPE_SUCCESS: "success",
    CONFIRM_TYPE_ERROR: "error",
    CONFIRM_TYPE_WARNING: "warning",
  }
  