import http, { fetchApi } from "@/utils/axios";
import URL from "@/api/urlConfig"
import moment from 'moment/moment';


// 获取所有人群
export const findAllCrowd = () => {
    return fetchApi(URL.GET_ALL_CROWD, 'null', 'get');
}

// 获取权益身份绑定列表
export const getEquityIdentityBindingList = (data, current, pageSize) => {
    const params = {
        benefitTitle: data.benefitTitle,
        pageNum: current,
        pageSize: pageSize
    }
    return fetchApi(URL.GET_EQUITY_IDENTITY_LIST,params, 'get',  'params')
}

// 修改权益身份绑定
export const updateEquityIdentityBinding = (data) => {
    const params = {
        id: data.id,
        crowdIdentity: data.crowdIdentity,
        benefitImage: data.benefitImage,
        remark: data.remark,
    }
    return fetchApi(URL.UPDATE_EQUITY_IDENTITY_BINDING_BY_ID, params, 'POST', 'data')
}

// 权益项目绑定查询
export const queryEquityByProjectId = (id) => {
    const data = {
        projectId: id
    }
    return fetchApi(URL.QUERY_EQUITY_BY_PROJECT_ID, data, 'get', 'params')
}

// 根据模块名称查询可以关联的群益身份
export const findEquityIdentifyByModuleName = (moduleName) => {
    const data = {
        moduleName: moduleName
    }
    return fetchApi(URL.QUERY_EQUITY_IDENTIFY_BY_MODULE_NAME, data, 'get', 'params')
}

// 权益绑定项目
export const projectBinding = (data) => {
    return fetchApi(URL.EQUITY_PROJECT_BINDING, data, 'POST', 'data')
}

// 导出所有权益身份绑定数据
export const downLoadAllEquityData = () => {
    return http({
        url: URL.EXPORT_EQUITY_IDENTIFY_DATA,
        method: "get",
        responseType: "blob",
        headers: {
            "Content-Type": "application/json;charset=utf-8",
        }
    })
}
// 查询项目列表
export const projectName = () => {
    return fetchApi(URL.BINDING_PROJECT_LIST, null)
}
// 查询权益管理list
export const getEquityManagementList = (data,pageNum,pageSize) => {
    data['pageNum'] = pageNum
    data['pageSize'] = pageSize
    return fetchApi(URL.GET_EQUITY_MANAGEMENT_LIST, data, 'get', 'params', true)
}
// 新增权益管理
export const addEquityManagement = (data) => {
    return fetchApi(URL.INSERT_EQUITY_MANAGEMENT, data, 'post', 'data')
}
// 编辑权益管理
export const editEquityManagement = (data) => {
    return fetchApi(URL.UPDATE_EQUITY_MANAGEMENT_BY_ID, data, 'put','data')
}

// 删除权益管理
export const toDeleteEquityById = (id) => {
    const param = {
        id: id
    }
    return fetchApi(URL.DELETE_EQUITY_BY_ID + '?id=' + id, '','delete', 'params')
}

// 查询权益通过id
export const findOneById = (id) => {
    const data = {
        id: id
    }
    return fetchApi(URL.GET_EQUITY_MANAGEMENT_BY_ID, data, 'get', 'params')
}

// 权益绑定列表
export const getEquityBindingList = (data,pageNum,pageSize) => {
    data['pageNum'] = pageNum
    data['pageSize'] = pageSize
    return fetchApi(URL.GET_EQUITY_BINDING_LIST, data, 'get', 'params', true)
}
// 根据项目id查询权益
export const getEquitiesByProjectId = (id) => {
    const data = {
        projectId: id
    }
    return fetchApi(URL.GET_EQUITY_BY_PROJECT_ID, data, 'get', 'params')
}
// 查询当前项目下的所属模块
export const getModulesByProjectId = (id) => {
    return fetchApi(URL.GET_MODULE_BY_PROJECT_ID + '/' + id + '/' + 6 + '/' + 3, '', 'get', 'params')
}
// 查询当前项目下的所属


// 新增权益绑定
export const addEquityBinding = (data) => {
    data['startTime'] = moment(data.effectiveDate[0]).format('yyyy-MM-DD HH:mm:ss')
    data['endTime'] = moment(data.effectiveDate[1]).format('yyyy-MM-DD HH:mm:ss')
    delete data.effectiveDate
    return fetchApi(URL.INSERT_EQUITY_BINDING, data, 'post', 'data')
}

// 启用禁用权益绑定
export const toStartUsingAndForbidden = (id, status) => {
    const data = {
        id: id,
        status: status
    }
    return fetchApi(URL.TO_START_USING_AND_FORBIDDEN,data, 'get', 'params')
}
// 通过id查询权益绑定
export const findEquityBindingById = (id) => {
    const data = {
        id: id
    }
    return fetchApi(URL.FIND_EQUITY_BINDING_BY_ID, data,'get', 'params')
}
// 删除权益绑定
export const toDeleteEquityBindingById = (id) => {
    return fetchApi(URL.DELETE_EQUITY_BINDING_BY_ID + '?id=' + id, '','delete', 'params')
}
// 编辑权益绑定
export const editEquityBindingManagement = (data) => {
    data['startTime'] = moment(data.effectiveDate[0]).format('yyyy-MM-DD HH:mm:ss')
    data['endTime'] = moment(data.effectiveDate[1]).format('yyyy-MM-DD HH:mm:ss')
    delete data.effectiveDate
    return fetchApi(URL.UPDATE_EQUITY__BINDING, data, 'put', 'data')
}