<template>
  <div class="serveManage">
    <ds-header title="权益绑定管理"></ds-header>
    <a-form layout="inline">
      <a-form-item label="所属项目：">
        <a-select
            style="width: 200px"
            v-model="searchForm.projectId"
            placeholder="全部"

        >
          <a-select-option :value="item.id" v-for="item in projectList" :key="item.id">
            {{ item.project_name }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="权益名称：">
        <a-input v-model.trim="searchForm.benefitTitle" placeholder="请输入权益名称" class="inputClass"/>
      </a-form-item>
      <a-form-item label="人群类型：">
        <a-select
            style="width: 200px"
            placeholder="请选择人员类型"
            v-model="searchForm.userLabel"
        >
          <a-select-option :value="item.id" v-for="item in crowds" :key="item.id">
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="生效时间：">
        <a-range-picker v-model="searchForm.time" />
      </a-form-item>
      <a-form-item label="状态：">
        <a-select
            style="width: 200px"
            v-model="searchForm.bindingStatus"
        >
          <a-select-option value="">全部</a-select-option>
          <a-select-option value="1">启用</a-select-option>
          <a-select-option value="0">禁用</a-select-option>
        </a-select>
      </a-form-item>
      <span>
        <a-button type="primary" @click="search" style="margin-top: 3px"> <a-icon type="search"/>查询 </a-button>
        <a-button style="margin-left: 15px" @click="resetSearchForm">重置</a-button>
      </span>
    </a-form>
    <div style="padding-top: 15px">
      <div class="upload-button-class">
        <div>
          <a-button type="primary" @click="add">
            <a-icon type="plus"/>
            绑定权益
          </a-button>
        </div>
      </div>
    </div>
    <div style="padding-top: 15px">
      <a-table :columns="columns" :pagination="false" :data-source="data" bordered>
        <template slot="operation" slot-scope="text, record">
          <div class="editable-row-operations">
            <span>
              <a @click="() => viewDetail(record)">查看</a>
            </span>
            <span>
              <a @click="() => edit(record)">编辑</a>
            </span>
            <span>
              <a @click="() => startUsingAndForbidden(record, 1)" v-if="record.bindingStatus === 0">启用</a>
              <a @click="() => startUsingAndForbidden(record, 0)" v-else>禁用</a>
            </span>
            <span>
              <a @click="() => deleteById(record)">删除</a>
            </span>
          </div>
        </template>
      </a-table>
      <div class="pagination-class">
        <a-pagination
            :show-total="total => `共 ${total} 条`"
            :page-size="pagination.pageSize"
            :default-current="pagination.current"
            :total="total"
            @change="pageChange"
            @showSizeChange="onShowSizeChange"
            show-quick-jumper
            showSizeChanger
        />
      </div>
    </div>
  </div>
</template>


<script>
import {mapState} from 'vuex';
import ConfirmBoxModule from '@/components/confirmBoxModule';
import DSTable from '@/components/DSComponents/DSTable';
import moment from 'moment'
import {
  getEquityBindingList,
  projectName,
  toDeleteEquityBindingById,
  findAllCrowd,
  toStartUsingAndForbidden
} from '../../../api/equityManagement';

const data = [];
export default {
  name: 'equityBinding',
  components: {
    DSTable,
    ConfirmBoxModule
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.load.loading,
      projectList: (state) => state.common.projectList
    })
  },
  data() {
    return {
      // 人群
      crowds: [],
      data: [],
      total: 0,
      searchForm: {
        projectId: undefined,
        benefitTitle: '',
        userLabel: '',
        time: [],
        bindingStatus: ''
      },
      pagination: {
        current: 1,
        pageSize: 10
      },
      columns: [
        {
          title: '所属项目',
          dataIndex: 'projectName',
        },
        {
          title: '权益名称',
          dataIndex: 'benefitTitle'
        },
        {
          title: '权益类型',
          dataIndex: 'benefitType',
          customRender: (text) => {
            if (text === 1) {
              return '固定权益'
            } else if (text === 2) {
              return '临时权益'
            } else {
              return '企业权益'
            }
          }
        },
        {
          title: '所属模块',
          dataIndex: 'moduleName'
        },
        {
          title: '适用人群',
          dataIndex: 'userLabel'
        },
        {
          title: '排序',
          dataIndex: 'bindingSort'
        },
        {
          title: '加锁状态',
          dataIndex: 'lockStatus',
          customRender: (text) => {
            if (text === 0) {
              return '不加锁'
            } else {
              return '加锁'
            }
          }
        },{
          title: '生效时间',
          dataIndex: 'startTime',
          customRender: (text, record, index) => {
            return record.startTime + ' - ' + record.endTime
          }
        },{
          title: '状态',
          dataIndex: 'bindingStatus',
          customRender: (text) => {
            if (text === 0) {
              return '禁用'
            } else {
              return '启用'
            }
          }
        },
        {
          title: '操作',
          scopedSlots: {customRender: 'operation'}
        }
      ]
    }
        ;
  },
  created() {
    this.findCrowds();
    this.list();
  },
  methods: {
    findCrowds() {
      findAllCrowd().then(res => {
        if (res.code === '200') {
          this.crowds = res.data
          this.crowds.unshift({
            id: '',
            name: '全部'
          })
        } else {
          this.$message.error(res.mag)
        }
      })
    },
    // 启用禁用
    startUsingAndForbidden(data, status) {
      let that = this
      this.$confirm({
        title: `确定要${status === 0 ? '禁用' : '启用'}  【${data.benefitTitle}】权益绑定吗?`,
        onOk() {
          toStartUsingAndForbidden(data.id, status).then(res => {
            if (res.code === '200') {
              that.$message.success('操作成功！')
              that.list();
            } else {
              that.$message.error(res.msg)
            }
          })
        },
        onCancel() {
          console.log('Cancel');
        },
        class: 'test',
      });
    },
    // 列表
    list() {
      if (this.searchForm.time.length > 0) {
        this.searchForm['startTime'] =  moment(this.setStartTime(this.searchForm.time[0])).format('YYYY-MM-DD HH:mm:ss')
        this.searchForm['endTime'] =  moment(this.setEndTime(this.searchForm.time[1])).format('YYYY-MM-DD HH:mm:ss')
      } else {
        this.searchForm['startTime'] =  ""
        this.searchForm['endTime'] =  ""
      }
      getEquityBindingList(this.searchForm, this.pagination.current, this.pagination.pageSize).then(res => {
        if (res.code === '200') {
          this.data = res.data.items;
          this.total = res.data.total;
          this.editDisabled = false;
        } else {
          this.$message.error('获取列表失败');
        }
      });
    },
    setStartTime(data) {
      let date = new Date(data);
      date.setHours(0);
      date.setMinutes(0);
      date.setSeconds(0);
      return date
    },
    setEndTime(data) {
      let date = new Date(data);
      date.setHours(23);
      date.setMinutes(59);
      date.setSeconds(59);
      return date
    },
    // 下载模版
    downLoadExcel() {
      let url =
          'https://oss-workplace-prod.innoecos.cn/picture_prod/052d380a14810000_%E6%9D%83%E7%9B%8A%E6%B1%A0%E8%A1%A8%E6%A0%BC%E6%A8%A1%E6%9D%BF.xlsx';
      window.open(url);
    },
    //查询
    search() {
      this.list();
    },

    //重置
    resetSearchForm() {
      this.pagination.current = 1;
      this.pagination.pageSize = 10;
      this.searchForm.benefitTitle = '';
      this.searchForm.projectId = '';
      this.searchForm.imageType = '';
      this.searchForm.time = []
      this.searchForm.bindingStatus =''
      this.searchForm.userLabel = ''
      this.list();
    },

    //分页操作
    pageChange(page, pageSize) {
      this.pagination.current = page;
      this.pagination.pageSize = pageSize;
      this.list();
    },
    onShowSizeChange(current, pageSize) {
      this.pagination.current = current;
      this.pagination.pageSize = pageSize;
      this.list();
    },
    // 新增
    add (){
      this.$router.push({
        path: '/equityManagement/equityBinding/add',
        query: {
          type: 'add'
        }
      })
    },
    viewDetail(data) {
      this.$router.push({
        path: '/equityManagement/equityBinding/add',
        query: {
          type: 'view',
          id: data.id
        }
      })
    },
    // 编辑
    edit(data) {
      this.$router.push({
        path: '/equityManagement/equityBinding/add',
        query: {
          type: 'edit',
          id: data.id
        }
      })
    },
    // 删除
    deleteById(data) {
      if(data.bindingStatus === 1) {
        this.$message.warn('该权益是启用状态，不可以删除！')
        return
      }
      let that = this
      this.$confirm({
        title: `确定要删除【${data.benefitTitle}】权益吗?`,
        onOk() {
          toDeleteEquityBindingById(data.id).then(res => {
            if (res.code === "200") {
              that.$message.success('操作成功')
              that.list();
            } else {
              that.$message.error(res.msg)
            }
          })
        },
        onCancel() {
          console.log('Cancel');
        },
        class: 'test',
      });
    },
    onChange(date, dateString) {
      this.searchForm.startTime = dateString[0]
      this.searchForm.endTime = dateString[1]
    },
  }
};
</script>

<style lang="scss" scoped>
.inputClass {
  width: 200px;
}

.upload-button-class {
  display: flex;

  & > div {
    padding-right: 15px;
  }
}

.editable-row-operations a {
  margin-right: 8px;
}

.pagination-class {
  padding-top: 20px;
  display: flex;
  flex-direction: row-reverse;
}

</style>
